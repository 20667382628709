function inIframe () {
    try {
        return window.self !== window.top;
    } catch (e) {
        return true;
    }
}

if ( inIframe() ) {
    document.querySelector('html').classList.add('iframed');
}