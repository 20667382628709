// Measures the width of the site's scrollbar and adds a data attribute to the body
// Create the measurement node
const scrollDiv = document.createElement('div');
scrollDiv.className = 'js-break-out-scrollbar-measure';
document.body.appendChild(scrollDiv);

// Get the scrollbar width
const scrollbarWidth = scrollDiv.offsetWidth - scrollDiv.clientWidth;

// Delete the DIV
document.body.removeChild(scrollDiv);

// Add data-scrollbarwidth to the body
document.body.setAttribute('data-scrollbarwidth', scrollbarWidth);