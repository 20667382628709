const inits = [
    { name: 'site_header', el: 'header#vue-header'},
    { name: 'site_page_title', el: 'section.page-title'},
    { name: 'site_footer', el: 'footer.footer'}
]

inits.forEach(i => {
    if (document.querySelector(i.el)) {
        window[`${i.name}_vue_instance`] = new Vue({
            el: i.el
        })
    }
})
